<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="leadDetails !== null">
        <div style="overflow-y: autos; white-space: nowrap;" class="lg:grid grid-cols-4 gap-4">
          <div class="col-span-2 ">
            <div>
              <div class="card rounded-md p-2">
                <div class="flex items-center justify-between">
                  <div class="text-text1" :class="mobileView ? 'heading-4' : 'heading-3'">● Contact Details:</div>
                </div>
                <div class="mt-1">
                    <div class="grid lg:grid-cols-2">
                      <div class="p-1">
                        <p class="mb-0">
                          <span class="text-gray4 heading-5 "><p class="mb-0" style="width:173px">Campaign Name:</p></span>
                          <span class="text-text2 heading-4 font-semibold whitespace-pre-line" >{{leadDetails.campaignName}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="py-1">
                      <div class="pb-4  rounded-md p-2 pt-0 my-2">
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">First Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.firstName !== ''">{{leadDetails.firstName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Last Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.lastName !== ''">{{leadDetails.lastName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Cell Number:</p></span>
                            <span class="text-text2 heading-5 font-semibold" >{{leadDetails.phoneNumber | phone}}</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Email:</p></span>
                            <span class="text-text2 heading-5 font-semibold overFlowParaA" v-if="leadDetails.leadOtherDetail.email !== ''">{{leadDetails.leadOtherDetail.email}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Address:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.address !== ''">{{leadDetails.leadOtherDetail.address}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">City:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.city !== ''">{{leadDetails.leadOtherDetail.city}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">State:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.state !== ''">{{leadDetails.leadOtherDetail.state}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Zip:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.zip !== ''">{{leadDetails.leadOtherDetail.zip}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-3">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Loan Date:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.loanDate !== ''">{{leadDetails.leadOtherDetail.loanDate | getFullDate}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Loan Type:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.loanType !== ''">{{leadDetails.leadOtherDetail.loanType}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Total Debt:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.totalDebt !== ''">{{leadDetails.leadOtherDetail.totalDebt | amountFormaterWithToFix}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-3">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Interest Rate:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.interestRate !== ''">{{leadDetails.leadOtherDetail.interestRate}}%</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Mortgage Balance:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.mortgageBalance !== ''">{{leadDetails.leadOtherDetail.mortgageBalance | amountFormaterWithToFix}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Fico:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.fico !== ''">{{leadDetails.leadOtherDetail.fico}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Lender Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.lenderName !== ''">{{leadDetails.leadOtherDetail.lenderName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Timezone:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.contactTimeZone === 'eastern_standard_time'">EST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'alaska_standard_time'">AKST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'hawaii-aleutian_standard_time'">HAST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'atlantic_standard_time'">AST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'chamorro_standard_time'">CHST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'pacific_standard_time'">PST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'mountain_standard_time'">MST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else-if="leadDetails.contactTimeZone === 'central_standard_time'">CST</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(otherDetails).length > 0">
                <div class="mt-2 card rounded-md p-2">
                  <span class="text-text2 heading-5 font-semibold">Other Details:</span>
                  <div class="pb-4 rounded-md p-2 pt-0 my-2">
                    <div class="grid md:grid-cols-2" >
                      <p class="mb-0 pt-2" v-for="(value, key) in otherDetails" :key="key">
                        <span class="text-gray4 heading-6">
                          <p class="mb-0" style="width:173px">{{ key }}:</p>
                        </span>
                        <span class="text-text2 heading-5 font-semibold">{{ value }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="mt-2 card rounded-md p-2">
                <div class="py-1">
                  <div class="mb-0 pb-0.5 flex items-center justify-between">
                    <span class="text-text2 heading-5 font-semibold" >Text Drips:</span>
                    <div class=" text-white cursor-pointer bg-primary px-2 py-1 rounded-xl" @click.stop="leadMessageList()">
                      <i class="fas fa-rotate  heading-6"></i>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="progress-bar" v-if="searchLoader">
                      <div class="progress-bar-value"></div>
                    </div>
                  </div>
                  <div class="overflow-auto custom-scrollbar" ref="messageContainer" :style="`max-height: ${cardHeight}px;`">
                    <div class="px-2" v-for="(data, index) in leadMsgArray" :key="index">
                        <div class="bg-green-50 p-1 rounded-md border" :class="mobileView ? 'my-2 ml-8' : 'my-3 ml-12'" v-if="!data.isReply">
                            <div class="p-2 w-full whitespace-pre-line" :class="mobileView ? 'heading-6' : 'mt-1'">{{ data.messageText }}</div>
                            <p class="mb-0 -mt-1">
                              <span class="text-gray4 text-right justify-end flex pr-2" :class="mobileView ? 'heading-7' : 'heading-6'">Sent: {{ data.sendReceiveDate | timeTitleHistory }}</span>
                              <span class="text-gray4 text-right justify-end flex pr-2 -mt-1" :class="mobileView ? 'heading-8' : 'heading-8'">{{ leadDetails.commPlatformNumber | filteredNumberStart}} ({{leadDetails.commPlatformType}})</span>
                            </p>
                        </div>
                        <div class="bg-red-50 p-1 rounded-md border" :class="mobileView ? 'my-2 mr-8' : 'my-3 mr-12'" v-if="data.isReply">
                            <div class="p-2 w-full whitespace-pre-line" :class="mobileView ? 'heading-6' : 'mt-1'">{{ data.messageText }}</div>
                            <p class="mb-0 -mt-1">
                                <span class="text-gray4 text-right justify-end flex pr-2" :class="mobileView ? 'heading-7' : 'heading-6'">Received: {{ data.sendReceiveDate | timeTitleHistory }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                </div>
              </div>
              <div class="">
                <div class="relative">
                  <div class="pb-4 card rounded-md p-2 my-2 mx-auto">
                      <div v-if="showTempolates">
                        <div class="border border-gray2 card rounded-md p-2 mb-1">
                          <div class="flex items-center justify-between -mt-1">
                            <span class="label_css font-semibold">Templates:</span>
                            <div class="flex items-center">
                              <span v-if="isSeletedAny" class="heading-7 font-semibold text-error cursor-pointer border border-error rounded px-1" @click="clearsg()">Clear Selection</span>
                              <div class=" text-text2 heading-5 cursor-pointer border border-text2 px-2 py-0.5 rounded-full ml-2" @click="showTempolates = !showTempolates">
                                <i class="fas fa-times"></i>
                              </div>
                            </div>
                          </div>
                          <div class="flex flex-wrap" v-if="textTemplateList.length > 0">
                            <button v-for="(data, index) in textTemplateList" :key="index" v-show="data.isTemplateActive" class=" w-auto outline-none rounded-lg hover:border-primary hover:text-text1 transition " :class="`${data.isSelected ? 'border border-primary bg-primary text-white' : 'border border-gray2 bg-white text-gray4'} ${mobileView ? 'heading-7 px-2 p-1 m-1' : ' heading-6 m-1 p-1 px-2 h-10 '}`" @click="minButtonSelectionHandler(data)">
                              <span>{{data.templateName}}</span>
                            </button>
                          </div>
                          <div class="layout my-2" v-if="textTemplateList.length === 0">
                            <div class="flex p-2 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
                              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                              <span class="sr-only">Info</span>
                              <div>
                                <p class=" font-medium heading-6 whitespace-pre-line">No active templates Found. <span @click="openTemplate()" class="cursor-pointer hover:underline">Click Here</span> to create template.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    <div class="relative">
                      <!-- <span @click="autoselect('clear')" class="absolute right-8 top-0.5 cursor-pointer px-1 mr-2 heading-7 rounded-lg text-error">(Clear)</span> -->
                      <TextAreaInput
                          :isShowBelow="true"
                          :inputId="'howtoreach'"
                          :inputext="message"
                          :placholderText="`Write your message here`"
                          :lableText="''"
                          :inputType="''"
                          :autoFocus="false"
                          :textMaxlength="1000"
                          :showcharLimit="true"
                          :cols="50"
                          :rows="3"
                          :setReadOnly="false"
                          :fieldError="msgErr !== ''"
                          @keyPressAction="msgErr = ''"
                          @inputChangeAction="(data) => message = data"  />
                          <p class="text-error heading-6 -mt-4">{{msgErr}}</p>
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex justify-end items-center">
                  <div class="d_icon_child" v-if="!showTempolates">
                    <button type="button" :class="` ${mobileView ? 'py-2 px-3 heading-7' : 'py-2 px-5 cta-text'}`" @click.stop.prevent="showTempolates = !showTempolates" class="bg-primary text-white rounded-xl shadow  hover:shadow-xl global_btn   hover:bg-opacity-75 ">
                      <span><i class="fas fa-sheet-plastic"></i></span>
                    </button>
                    <span class="tooltiptext heading-6 bg-transparent whitespace-nowrap rounded">{{showTempolates ? 'Close' : 'Text Template'}}</span>
                  </div>
                  <Button class="ml-3" @buttonAction="sendMessageToLead()" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Message'"/>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 left-2"><i class="fas fa-caret-up text-white"></i></button>
  </div>
</template>
<script>
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    TextAreaInput
  },
  mixins: [deboucneMixin],
  data () {
    return {
      isSeletedAny: false,
      textTemplateList: [],
      showTempolates: false,
      leadDetails: null,
      otherDetails: null,
      leadMsgArray: [],
      cardHeight: '',
      msgErr: '',
      message: '',
      istart: false,
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      openEditPopup: false,
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
      searchLoader: false,
      competitionDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    document.title = 'Lead Detail'
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    this.getContactDetail()
    this.leadMessageList()
    this.getTwilioListing()
  },
  watch: {
    message: {
      handler() {
        if (this.message === '') {
          this.textTemplateList.forEach(element => {
            this.isSeletedAny = false
            element.isSelected = false
          });
        }
      },
      deep: true,
    },
    leadMsgArray: {
      handler() {
        // this.$nextTick(() => {
        //   this.scrollToLastMessage();
        // });
      },
      deep: true,
    },
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    }
  },
  methods: {
    clearsg () {
      this.message = ''
      this.textTemplateList.forEach(element => {
        this.isSeletedAny = false
        element.isSelected = false
      });
    },
    minButtonSelectionHandler(data) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.MergeSmsTemplate(
        data.templateId,
        parseInt(this.$route.query.campaignMasterId),
        parseInt(this.$route.params.leadId),
        response => {
          this.message = response.Data
          this.isSeletedAny = true
          this.msgErr = ''
          this.textTemplateList = this.textTemplateList.map(element => ({
            ...element,
            isSelected: element.templateName === data.templateName
          }));
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getTwilioListing () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
      ADMINAPI.SmsTemplateActiveList(
        response => {
          let tempData = response.Data !== null ? response.Data  : null
          if (response.Data !== null) {
            tempData.forEach(element => {
              this.isSeletedAny = false
              element.isSelected = false
            });
            this.textTemplateList = tempData
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
    button1 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Copied successfully', color: 'success'})
    },
    button2 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'In progress.....', color: 'secondary'})
    },
    button3 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'In progress.....', color: 'secondary'})
    },
    openTemplate () {
      let url = this.$router.resolve({name: 'Template'})
      window.open(url.href, '_blank')
    },
    sendMessageToLead () {
      if (this.message === '') {
        this.msgErr = 'Message is required'
      } else {
        // Send Message Through the Twilio Platfrom
        if (this.leadDetails.commPlatformType === 'twilio') {
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.SendSmsLeadScreen(
            parseInt(this.$route.query.campaignMasterId),
            parseInt(this.$route.params.leadId),
            this.leadDetails.commPlatformConfigId,
            this.message,
            this.leadDetails.phoneNumber,
            true,
            response => {
              this.message = ''
              console.log('FROM MESSAGE RESPONSE.............-->', response)
              this.leadMessageList()
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            error => {
              this.isLoading = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
        // Send Message Through the Plivo Platfrom
        if (this.leadDetails.commPlatformType === 'plivo') {
          this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
          ADMINAPI.SendLeadSMSViaPlivo(
            parseInt(this.$route.query.campaignMasterId),
            parseInt(this.$route.params.leadId),
            this.leadDetails.commPlatformConfigId,
            this.message,
            this.leadDetails.phoneNumber,
            true,
            response => {
              this.message = ''
              console.log('FROM MESSAGE RESPONSE.............-->', response)
              this.leadMessageList()
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            },
            error => {
              this.isLoading = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      }
    },
    getContactDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetSentDripContactDetail(
        parseInt(this.$route.params.leadId),
        parseInt(this.$route.query.campaignMasterId),
        response => {
          this.leadDetails = response.Data
          if (response.Data.leadOtherDetail.additionalData !== '') {
            this.otherDetails = JSON.parse(response.Data.leadOtherDetail.additionalData)
          } else {
            this.otherDetails = ''
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    scrollToLastMessage() {
      console.log('messageContainer', this.$refs.messageContainer)
      if (this.$refs.messageContainer) {
        const messageContainer = this.$refs.messageContainer;
        messageContainer.scrollTop = messageContainer.scrollHeight;
      } else {
        console.log("messageContainer not found");
      }
    },
    leadMessageList () {
      this.searchLoader = true
      // this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetContactSentDripMessageList(
        0,
        10000,
        parseInt(this.$route.params.leadId),
        parseInt(this.$route.query.campaignMasterId),
        response => {
          console.log('response', response)
          this.leadMsgArray = response.Data.tableRow !== null ? response.Data.tableRow : []
          setTimeout(() => {
            this.scrollToLastMessage();
          }, 100);
          setTimeout(() => {
            this.searchLoader = false
          }, 300);
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
        if (window.innerWidth < 1263) {
          this.mobileView = true;
          this.cardHeight = window.innerHeight - 273
        } else {
        this.cardHeight = window.innerHeight - 473
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
.progress-bar {
  height: 3px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.card1 {
  max-width: 98%; /* Adjust as needed */
  margin: auto; /* Center the card */
  padding: 1rem; /* Add some internal spacing */
}

.absolute1 {
  left: 50%; /* Centering for dropdowns */
  transform: translateX(-50%); /* Adjust for the width */
}


/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  background-color: #f0f0f0; /* Background color of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

.scrollbar {
  scrollbar-width: thin; /* For Firefox: make scrollbar thin */
  scrollbar-color: #888 #f0f0f0; /* thumb and track color */
}

/* Ensure that the custom scrollbar class is applied */
.custom-scrollbar {
  overflow-y: auto; /* Make sure to add this for vertical scroll */
}
.d_icon_child {
  position: relative;
}
.d_icon_child:hover .tooltiptext {
  visibility: visible;
}
.d_icon_child:hover .tooltiptext2 {
  visibility: visible;
}
.tooltiptext {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  background-color: #0000009c;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -25px;
  left: -30px;
}
.tooltiptext2 {
  visibility: hidden;
  width: 80px;
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  background-color: #0000009c;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -25px;
  left: -30px;
}
</style>
